<template lang="pug">
modal(size="sm" @close='$emit("close")')
  generic-form(
    :endpoint='`/_/RejectInquiryForm`'
    :allowShortcuts='true'
    @submitSuccess='$emit("close")'
  )
    template(#default)
      form-header
        | {{$t("Reject Inquiry")}}
      form-hidden(name='Id' :modelValue='id')
      form-textarea(name="RejectReason")
      form-footer
        form-action(type="secondary" :primary='true' name="SendReject")
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormRejectInquiry = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
})
export default FormRejectInquiry

</script>
